module.exports = {
	common: {
		addAccount:'Add Account',
		currentAccount:'Current Account',
		customerService:'Online customer service',
		gptMsg:'Type your message here...',
		sendGpt:'Send',
		clearGptHistory:'Clear History',
		socketClose:'Disconnect detected, reconnecting...',
		replyWait:'Customer service is trying to reply, please wait for a moment',
		title:'Lightning',
		endtime:' Valid until',
		contact:'Contact us',
		exchange:'Exchange',
		pair:'Symbol',// Trading pair / Pair
		opensea:'NFT Token',
		currency:'Currency',
		available:'Available',
		frozen:'Allocated',
		change:'Change',
		total:'Total',
		currentOpen:'Current Trading Pair',
		collection:'Favorite Trading Pair',
		commonly:'Favorites',
		explain:' Explanation',
		addTile:'Add ',
		editTitle:'Edit ',
		description:'Function Manual',
		num:'Amount',//Quantity
		pirce:'Price',
		purchase:'Buy',
		sellout:'Sell',
		closed:'Close Order',//Filled
		direction:'Direction',//Side
		time:'Time',
		operation:'Operate',
		recharge:'Recharge',
		withdrawal:'Withdrawal',
		exchangeSelect:'Select an Exchange',
		whole:'All',
		agreement:'User Agreement',
		mainAddress:'Funds-wallet address',
		subAddress:'Unauthorized address',
		gasCurrency:'Gas currency',
		token:'Token authorize list',
		filter:'Filter',
		content:'Content',
		selectTime:'Select Time',
		valuation:'Valuation',
		proportion:'Proportion',
		gas:'Service fee',
		targetAddress:'Target Address',
		balance:'Balance',
		to:'to',
		second:' second',
		warning:'Warning',
		success:'Success',
		notice:'Notice',
		slippage:'Slippage',
		selectAll:'Select All',
		recommend:'Recommand',
		securityCode:'Security Code',
		securityCodePH:'Please enter the security code',
		walletAddress:'Wallet Address',

		// 按钮
		renew:'Renew',
		open:'permission',//Apply for authorization/ Relase for authorization //permission/authorization
		handicap:'Enter Pair',
		into:'Enter',
		selectOpen:'Select Trading Pair',
		back:'Back',
		next:'Next',
		pre:'Previous',
		continueAdd:'Continue adding',
		add:'Add',
		add2:'Add',
		edit:'Edit',
		modify:'Modify',
		bind:'Bind',
		remove:'Unbind',
		removeBind:'Unbind',
		toOpen:'Apply for Authorization',
		backlist:'Back to List',
		search:'Search',
		confirm:'Confirm',
		beforeDay:'Day Before',
		nextDay:'Day After',
		submit:'Submit',
		cancel:'Cancel',
		confirm2:'Confirm',
		save:'Save',
		saveRun:'Save and Start',
		restart:'Restart',
		copy:'Copy',
		cancelOrder:'Cancel Order',//Cancel
		start:'Start',
		stop:'Stop',
		run:'Start',
		del:'Delete',
		permanentlyDel:'Delete',
		toBind:'email bind',
		toAuthorize:'To authorize',//'Go to authorization',
		authorize:'authorize',
		toAllocation:'Allocate',
		toErrLog:'Error Log',
		allotAuthorize:'Allocate and Authorize',
		directAuthorize:'Direct Authorize',
		ignore:'Ignore',
		viewBtn:'View',
		refused:'Ignore',
		update1:'Update',
		update2:'Update',
		downLoad:'Export',
		override:'Override',
		switchAccount:'Switch Account',
		settings:'Settings',
		viewLog:'View Logs',
		toEnable:'Enable',
		enableNow:'Enable',
		more:'More',
		clear:'Clear',

		// 单选
		yes:'YES',
		no:'NO',

		// 输入框占位符
		select:'Select',//Please select 
		input:'Enter',//Please enter 
		value:'Value',//Amount
		valueOptional:'Value (Optional)',
		minValue:'Min Value',
		maxValue:'Max Value',
		inputVerification:'Please enter the verification code',
		selectdate:'Select Date',
		startdete:'Start Date',
		enddete:'End Date',
		keyword:'Keyword',//Please enter a keyword
		optional:'Optional',
		timePH:'such as: 2024-01-01 01:01:01',

		telegram:'Telegram',
		pwd:'Password',
		email:'Email',
		emailAddress:'Email Address',
		newPwd:'New Password',
		confirmNewPwd:'Confirm new password',
		oldEmail:'Old Email',
		newEmail:'New Email',
		verification:'Email Verification Code',
		verification_s:'Verification Code',
		countDown:'',
		sendVerification:'Send',//Send Verification Code
		resend:'Resend',

		max:'Maximum',
		maxBtn:'Max',

		// sub
		userName:'Username',
		login:'Login',
		register:'Register',
		logOut:'Log Out',

		documentation:' Documents',
		freeRegistration:'Register',//Register for free

		loding:'Loading',
		noMore:'No More Information',
		noData:'No Data',
		
		subManage:'Manage Wallet',
		toSeeBtn:'View',
		enabled:'Enabled',
		notEnabled:'Disable',

		// 交易记录
		transactionRecord:'Transaction Record',
		address:'Address',
		tradingDirection:'Trading Direction',
		transactionAverage:'Average Deal Price',//Average Deal Price
		transactionNum:'Deal Quantity',
		transactionAmount:'Total Deal Amount',
		orderStatus:'Order Status',
		orderStatus1:'Fail',
		orderStatus2:'Success',
		orderStatus3:'Trading in',
		viewTX:'View TX',

		query:'Search',
		toLogin:'to Log in',
		loginAgain:'Log in again',
		refresh:'refresh',
		// toClose:'To shut down'
	},
	// 新手引导
	guide:{
		skipStep:'Skip this step',
		skipTutorial:'Skip the tutorial',
		selectWeb:'Select Exchange',
		selectWebSub:'Select an exchange and fill in the trading pair.',
		bindAccount:'Bind Account',
		bindAPI:'Bind API',
		bindAPISub:'Please use real funds.',
		iniContract:'Initialize contract',
		contractSub:'Please fill in the token contract address.',
		bindWallet:'Bind wallet',
		bindWalletSub:'Please fill in your private key and wallet address below.',
		distribution:'Allocate Assets',
		distributionSub:'Reply to the following questions to complete allocation and authorization.',
		subWalletNum:'How many trading wallets need to be created?',
		allotAuthorize:'Do you authorize the system to manage fund allocation and token authorization on your behalf?',
		accountBalance:'Account Balance',

		selectRobot:'Select Robot',
		welcome:'Welcome',
		guideDetail:"Complete the following beginner's guide to quickly understand Lightning MM (the entire process takes about five minutes).",
		
		// placeholder
		baseCurrencyPH:'Please enter the base currency',
		quoteCurrencyPH:'Please enter the quote currency (e.g., USDT, ETH, BNB, USDC)',
		contractPH:'Please enter the token contract address',
		walletAddressPH:'Please enter your wallet address',
		privateKeyPH:'Please enter your private key',
		fillIn:'Please enter {name}',
		req:'Question ',
		subWalletNumPH:'Please enter the number of trading wallets to create',
		allotAuthorizePH:'Please select whether to authorize the system for allocation and authorization',
		allocateNumPH:'Please enter the allocation quantity',

		req1:'Why bind?',
		ans1:'A cryptocurrency exchange is the platform where you buy and sell cryptocurrencies, with your funds remaining on the exchange. To start automated trading with Light, you need to bind your exchange account and enable trading permissions.',
		
		// step2
		// cex
		bindapi_title:'Create an API key on {exchange}',
		bindapi_1:'Log in to {exchange}',
		bindapi_2:'Find <API> in the top-left toolbar',
		bindapi_3:"Complete the API creation according to the exchange's instructions.",
		bindapi_4:'Choose a passphrase and add an IP address',
		ipWhitelist:'IP addresses to whitelist',
		bindapi_5:'Enable "Trading" and click Confirm',
		bindapi_6:'Copy and paste your key and password here',
		// dex
		req2_1:"Why is it necessary to add the coin's contract address in the Lightning backend?",
		ans2_1: "The DEX market allows tokens with the same symbol, and the contract address is the most accurate way to distinguish tokens.",
		req2_2:"How can I obtain the contract address?",
		ans2_2:"Please consult the technical personnel who wrote the DEX contract.",
		req2_3: "Why is it necessary to bind the wallet address and private key?",
		ans2_3: "It is used to import external market-making funds into the transaction wallet of the system. The bound wallet address (also known as the funding wallet/main wallet) only serves as an intermediary for fund transfers.",
		req2_4: "How can I obtain the wallet address and private key?",
		ans2_4: "Please obtain them from your on-chain wallet.",
		req2_5: "Are the wallet address and private key safe on the Lightning platform?",
		ans2_5: "The Lightning platform employs multiple encryption techniques when users submit private keys or highly sensitive information, and implements ciphertext storage to ensure the security of private key information.",
		req2_6:'Why is it necessary to set up a trading wallet?',
		ans2_6:'The addresses of each transaction on the dex exchange are visible to everyone. To avoid revealing the actions of the market maker to retail investors, multiple trading wallets are typically used to disguise user transactions.',
		req2_7:'Is the trading wallet secure?',
		ans2_7:'After the trading wallet is generated on the platform, it undergoes multiple encryption processes and implements ciphertext storage. The private key of the trading wallet is inaccessible to anyone.',
		req2_8: 'How many trading wallets need to be created?',
		ans2_8: 'Create 20 wallets (suitable for low-cost solutions)<br/>Create 50 wallets (suitable for standard solutions)<br/>Please create an appropriate number based on your specific needs.',
		
		// 检测loading
		checkErr:'Detection failed',
		checkIng:'Detecting...',
		checkBind:"Check the user's bound {name}",
		checkSuc:'Detection successful',
		checkBindErr:'Binding failed, please check {name}',

		// 机器人引导
		configuration:'Robot Configuration Guide',
		configurationSub:"Answer the following questions to complete the beginner's guide",
		// cex
		openCexLabel:'OpenBell Trader',
		openCexTip:'This robot is used to secure the opening price and the highest price instantly at market opening, then bring the price down to the pre-determined consolidation level.',
		req3_1:'Have you already opened the market?',
		budanNewLabel:'Orderbook Management',
		budanNewTip:'It is used to control the bid-ask spread, the depth of buy and sell orders, and maintain the activity of placing and canceling orders, thereby increasing market liquidity.',
		req3_2:'Set the order book quantity parameters',
		numberOfOrderLevels:'Number of order levels',
		numberOfOrderLevelsPH:'Please specify the number of orders you want to place',
		spreadPerLevel:'Spread per level',
		spreadPerLevelPH:'Please specify the price difference between each order',
		shuaLabel:'Volume Generating',
		shuaTip:'Used to increase order book trading volume and improve market activity.',
		req3_3:'Set daily trading volume',
		dailyRequiredWashTradingVolume:'Required daily trading volume',
		minValueOfTheQuantityRangePerWashTrade:'Minimum value of the transaction quantity range per order',
		maxValueOfTheQuantityRangePerWashTrade:'Maximum value of the transaction quantity range per order',
		// dex
		frontrunDexLabel:'Dex Scheduled Buy',
		frontrunDexTip:'The robot will buy an order of a set amount at the specified time. Due to excessive price fluctuations, the buying price may be suboptimal or the purchase may fail.',
		reqDex3_1:'Do you need to rush to buy at market opening?',
		shuaDexLabel:'DEX Charting',
		shuaDexTip:'This feature is designed to create random fluctuations within a specified price range, generate trading volume, increase market activity, and plot the trading candlestick chart for DEX pairs.',
		reqDex3_2:'Set the working range of the robot',
		reqDex3_2Tip:'* Fill in the numbers from low to high to control the drawing range. If the range is exceeded, the robot will try its best to operate within the set limits.',
		req3_3Tip:'* Fill in the numbers from low to high. The trading volume for each transaction will be a random range, which can be set larger to make the trading records appear more natural.',
		maxPurchasePrice:'Maximum purchase price',
		gasMultiplier:'Gas multiplier',
		openingPurchaseAmount:'Opening purchase amount',
		
		runRobot:'Start the robot',
		settingSuc:'Setup successful',
		runRobotDexLabel:'Important: ',
		runRobotDexTip:'Robot parameters are set based on the price of the trading pair, not the price of USDT. For example, in the BNB trading pair, all operations are based on the price fluctuations of BNB, not the changes in the USDT price.',
	},
	head:{
		lv:'User Level',
		center:'User Center',
		invite:'Referral',
		notice:'Latest announcements',
		out:'Log out',
		outTip:'Confirmed to log out?',
	},
	aside:{
		list:[
			'Account List',
			'Manual Operate',
			'Trading Robot',
			'Robot Log',
			'Data Report',
			'Assets Report',
			'Alert',//'Alarm System',
			'Settings',
			'User Center',
			'Referral',//'Invite',
			'Support',
			'Tutorial',//"Beginner's Toturial",
			'Contact Us',
		],
		renew:'Recharge',//Recharge to release authorization
	},
	// 官网相关
	website:{
		index:{
			lightMM:'Lightning Market Making System',
			mm:'Market Making System',
			sub:'6 years of seamless operation.  The safest and most potent market-making system globally.  Now supporting all major Dexes!',
			project:'Project partners',
			multiChain:'Support multi-chain Dexes',
			// f2
			fullyAutomatic:'Fully automatic price fluctuations',
			followSystem:'Market Syncing System',
			sellSuck:'Smart Arbitrage Strategies',
			assetWarning:'Asset Alarm System',
			// f3
			packages:'Packages',
			month:'Month',
			// f4
			features:'Our Features',
			featuresDetail:'Safe, Powerful, and Convenient',
			safe:'Safe',
			safeSub:'Assets are deposited on exchanges, and account information is multiple encrypted.',
			power:'Powerful',
			// powerSub:'The core program and logic of Light robot have years of research and development.',
			powerSub:'The core program and logic of Lightning Market Making System have years of research and development.',
			convenient:'Convenient',
			convenientSub:'Aggregate management of multiple accounts enables you to operate freely on multiple terminals.',
			efficient:'Efficient',
			efficientSub:'Efficient operation',
			// f5
			customers:'Suitable For Whom',
			who:'Who do we serve?',
			market:'Market maker',
			incubator:'Incubator',
			// foot
			links:'Links',
			// storm:'Storm Orders Track',
			// lightning:'Lightning Quantify',
			follow:'Follow Quantify',
			LightningDexTools:'Lightning DexTools',
			service:'Service Center',
			cooperation:'Business Cooperation',
			problem:'Common Issues',
			terms:'Terms and Conditions',
			privacy:'Privacy Policy',
		},
		agreement:{
			protocol:'User Agreements',//USER PROTOCOL
			content:`
			<p>The Light system is developed and operated by Singapore Jumu Capital co. ltd. 

				The company's website http://www.llkkz.net (hereinafter referred to as "this website" or "website") is a service that provides blockchain industry services (hereinafter referred to as "the service"). ” or “Services”) platform. 
			
				In order to protect your rights, please read and fully understand the entire contents of the user protocol before registering or using this website.
			</p><br/>
			<h3>1. General</h3>
			<p>
				1.1 The User Procotol (hereinafter referred to as the "Protocol") consists of the main text, the Privacy Policy, and various rules, statements, instructions, etc. that have been published or may be published in the future on this website.<br/>

				1.2 Before using the services provided by this website, you should read this protocol carefully. 

				If you have any incomprehension or other necessity, please consult a professional lawyer. 

				If you do not agree to this agreement and/or its modifications at any time, please stop using the services provided by this website or log in to this website immediately. 

				Once you log in to this website, use any service of this website or any other similar behavior, you have understood and fully agree to the contents of this protocol, including any modification made by this website at any time to this protocol.<br/>

				1.3 You can become a user of this website (hereinafter referred to as "user") by filling in relevant information in accordance with the requirements of this website and successfully registering through other relevant procedures. 

				Clicking the "Agree" button during the registration process means that you electronically sign;

				Or when you click any button marked "Agree" or similar in the process of using this website, or when you actually use the services provided by this website in other ways permitted by this website, you are fully understand, agree and accept all the terms under this protocol. 

				As far as this agreement is concerned, the absence of your handwritten written signature will have no effect on your legal binding force.<br/>

				1.4 After you become a user of this website, you will obtain a user account and corresponding password, and the user account and password shall be kept by the user;

				You are legally responsible for all activities and events that occur with your account.<br/>

				1.5 Only users who become users of this website can use the blockchain industry services provided by this website; non-registered users can only browse the content of the website.<br/>

				1.6 By registering and using any of the services and functions provided by this website, you will be deemed to have read, understood and:<br/>

				1.6.1 accept all terms and conditions of this Protocol;<br/>

				1.6.2 You confirm that you are at least 18 years of age or the legal age to enter into a contract under different applicable laws.

				Your registration on this website, posting of information, etc. to accept the services of this website shall comply with the relevant laws and regulations of the sovereign country or region that has jurisdiction over you, and be fully capable of accepting these terms；<br/>

				1.6.3 You confirm that the information provided during registration is true and accurate;<br/>

				1.6.4 You agree to abide by any relevant laws;<br/>

				1.6.5 You agree that at no time shall you engage in or participate in acts or activities that damage this website or the Light Robot, whether or not in connection with the services provided by this website
			</p>
			<br/>
			<h3>2. Amendments to the Protocol</h3>
			<p>2.1 Lightning reserves the right to revise this protocol from time to time and make announcements in the form of website announcements, without notifying you separately.

			Once the changed protocol is published on the website, it will automatically take effect immediately.
			
			You should browse and pay attention to the update time and update content of this Protocol from time to time.
			
			If you do not agree with the relevant changes, you should immediately stop using the services of this website.
			
			If you continue to use the services of this website, it means that you accept and agree to be bound by the revised protocol.
			</p>`,
		},
		problem:{
			question1:'How does the system ensure the security of account assets?',
			// answer1:'Double protection: 1. Light robot has multiple protection modules to deal with different attacks and arbitrage behaviors. 2. The alarm system can monitor the panel according to your settings, and it will stop all robots immediately if there is an abnormality.',
			answer1:'Double protection: 1. Lightning Market Making System has multiple protection modules to deal with different attacks and arbitrage behaviors. 2. The alarm system can monitor the panel according to your settings, and it will stop all robots immediately if there is an abnormality.',
			// question2:'Can one Light system account be connected to multiple exchange market-marking accounts?',
			question2:'Can one Lightning Market Making System account be connected to multiple exchange market-marking accounts?',
			// answer2:'The Light system currently supports more than 60 exchanges. You can bind the trading accounts of multiple exchanges in a single Light account to complete the aggregation management of multiple accounts.',
			answer2:'The Lightning Market Making System currently supports more than 60 exchanges. You can bind the trading accounts of multiple exchanges in a single Light account to complete the aggregation management of multiple accounts.',
			question3:'How to check the profit and loss of assets?',
			// answer3:'The Light system will record the account balance of the day at 0:00 every day. You can check the historical daily profit and loss by comparing the daily balance changes.',
			answer3:'The Lightning Market Making System will record the account balance of the day at 0:00 every day. You can check the historical daily profit and loss by comparing the daily balance changes.',
			question4:'How to retrieve the forgotten password?',
			answer4:'Please contact customer service, the customer service will check the account ownership with you and reset the login password for you. After logging in, you can change the password in the personal center.',
			question5:'Others',
			// answer5:'If you have any use problems or suggestions on the product, we very much welcome you to contact us, we will do our best to meet your needs! Thank you for your support of Light Robot.',
			answer5:'If you have any use problems or suggestions on the product, we very much welcome you to contact us, we will do our best to meet your needs! Thank you for your support of Lightning Market Making System.',
		},
		contact:{
			text1:'The Light cloud system was developed by Singapore LIGHT Tech. in 2016 to summarize the mature trading techniques in the cryptocurrency, and it took one year to design and develop it. As the most professional market-making/trading robot supplier in the cryptocurrency, the system has been running for 6 years, serving hundreds of project-side market-making teams.',
			// text2:"Light cloud system currently supports more than 200+ CEXes. The latest {version} system has taken the lead in supporting a number of Dexes, becoming the industry's leading market value robot service provider. There are mutiple system strategies and functions available, it is the best choice for your market making/trading.",
			text2:"Light cloud system currently supports more than 200+ CEXes. The latest {version} system has taken the lead in supporting a number of Dexes, becoming the industry's leading market making system service provider. There are mutiple system strategies and functions available, it is the best choice for your market making/trading.",
			business:'Business Cooperation',
			service:'Customer Service',
		}
	},
	// 登录相关模块
	login:{
		loginTitle:'Login',
		forget:'Forgot password?',
		toRegister:'To sign up',//No account? Go register
		agree:'Agree with Lightning',
		version:'Version',//System 
		// 忘记密码
		reset:'Reset Password',
		confirm:'Confirm Password',
		confirmReset:'Confirm to reset',
		// 注册
		register:'Sign up',
		toLogin:'Already have an accont? Go to login',
		safeLogin:'Secure login',
	},
	// 输入框提示
	tip:{
		usernameEmpty:'Please enter Username',
		accountPlaceHolder:'Min. 6 characters, with letters and numbers',
		accountVer:'Username must be more than 6 characters, and contain both letters and numbers',
		emailEmpty:'Please enter email address',
		emailVer:'Please enter the correct email address',
		codeEmpty:'Please enter the verification code',
		pwdEmpty1:'Please enter a password',
		pwdEmpty:'Please enter a new password',
		pwdVer:'Password must be 8 characters or more, and must include symbols, uppercase letters, and numbers',
		pwd2:'The two passwords do not match',
		commonVer:'Min. 8 chars, with symbols, upper & lower case, numbers',
		again:'Please check the new password once again',
		invite:'Referral Code',
		inviteVer:'Please enter the referral code',
		check:'Please check the user policy and privacy agreement',
		tip:'Tip',
		tips:'Tips',
		expired:'The authorization has expired, if you need to use,  please renewal!',
		waiting:'Coming soon...',
		waiting2:'The feature is not yet available. Please contact customer service!',
		registerSuc:'Registration success!',
		copysuccess:'Copy success!',
		copyerr:'The current browser does not support copying!',
		timeFormatErr:'Invalid date format. Please use YYYY-MM-DD HH:mm:ss',
		timeFormatErr2:'Invalid date. Please enter a valid date and time.',
		currencyEmpty: 'Please select a currency',
		// 收藏
		collectionSuc:'Added to Favorites',
		cancelCollectionSuc:'Remove form Favorites',
		// 账号列表
		pleaseEnter:'Please enter {name}',
		addSuc:'Added sucess!',
		addErr:'Added failed!',
		delTip:'Confirmed to delete?',
		delSuc:'Deleted success!',
		delErr:'Deleted Failed!',
		// 手动操盘
		selectExchange:'Select exchange',//'Please select an exchange',
		enterPair:'Please enter a symbol',
		enterCollect:'Please enter the collection',
		numEmpty:'A valid amount is required!',
		priceEmpty:'A valid price is required!',
		buySuc:'Buy-in success!',
		sellSuc:'Sold-out success!',
		handicap:'A valid trading pair is required!',
		cancelOrder:'Confirm to cancel order?',
		cancelSuc:'Order cancel success!',
		manuallyBuy:'Confirm to manually place buy order?',
		manuallySell:'Confirm to manually place sell order?',
		needSell:'Sell {num1} to receive {num2}',
		needBuy:'Consume {num1} to buy {num2}',
		calculationRes2:'Target Price {num1}, Slippage {num2}',
		total500:'Total order volume maximum 500',
		// 交易记录
		recordListTip:'Records within 3 months only',
		// 交易钱包管理
		privateKey:'Please enter the private key of trading-wallet!',
		toSee:'Check the log to view detailed results!',
		toMainRefresh:'Confirm to refresh funds-wallet?',
		// createSuc:'<div>Trading-wallet has been created!<br/><span style="color:#E65B1B">【Note:Please allocate funds to your trading-wallets before using it. 】</span></div>',
		createSuc:'<div>The trading wallet creation request has been submitted! Please check the logs for the detailed result.<br/><span style="color:#E65B1B">【Note:Please allocate funds to your trading-wallets before using it. 】</span></div>',
		num0:'The number cannot be 0',
		totalQuantity0:'Total amount cannot be 0',
		closeCollectionTip:'Closing will not terminate the allocation operation. Please check the logs for specific results.',
		closeAllocationTip:'Closing will not terminate the collection operation. Please check the logs for specific results.',
		frequentTip:'Your actions are too frequent. Please try again later!',
		exceedBalance:'The amount you entered cannot exceed your balance.',
		// 操盘机器人
		saveSuc:'Save success!',
		saveErr:'Save failed!',
		startRobot:'Confirmed to start the robot?',
		startSuc:'Start success!',
		startErr:'Start failed!',
		stopRobot:'Some issues might be caused when the robot terminated. Confirmed to terminate the robot?',
		saveRobot:'Are you sure you want to save the robot settings?',
		stopSuc:'Terminated success!',
		stopErr:'Terminated failed!',
		dataErr:'Parameters incomplete!',
		operationSuc:'Operation success!',
		waitConfirmation:'Transaction submitted, awaiting block confirmation.',
		toRefresh:'Confirmed to refresh the balance?',
		refreshSuc:'Refresh success!',
		processing:'Uploaded success! Processing...',
		calculationSuc:'Calculation Result: ',
		noRobot:'There are currently no robots in operation!',
		closingErr:'Closing, please hold on...',
		closing:'It will automatically shut down after {time} seconds',
		restart:'The robot is rebooting, please wait a moment... {time}',
		mainSaveRunTip:'The current transaction authority of the funds-wallet is enabled. Once the robot sets up, there is a possibility for the funds-wallet to place an order in the transaction. If you do not want the funds-wallet involved in transactions, please disable this function and restart the robot.',
		mainRunTip:'The transaction authority of the funds-wallet is enable, and the funds-wallet would participate in placing order. If you do not want the funds-wallet involve in transactions, please go to [Manage Wallet] to disable transaction authority.',
		priceErrQuShi:'The single transaction budget must be less than or equal to the total budget',
		priceDifferenceErr:'Spread per level must be less than or equal to the spread size',
		paramSame:'Detected parameters are consistent with the already started robot. Please modify and save again',
		notBindTG:"Do you want to send system messages to your Telegram account? If you don't need it now, you can also set it up later through the 'Bind to Telegram' option on the 'Alert' page.",
		// 机器人
		calculate:'Confirm the calculation?',
		balanceRefresh:'Are you sure to update your account wallet balance?',
		delieverToken:'Confirm the transfer of funds from the funds-wallet to the trading-wallet?',
		collectToken:'Confirm the transfer of funds from the trading-wallet to the funds-wallet?',
		walletsApprove:'Is the wallet authorized to trade the token?',
		rangeErr:'The selected time range cannot exceed 7 days.',
		// 数据报表
		exportSend:'Transaction details will be sent to your email later',
		sendSuc:'Send successfully, please note to check!',
		noEmail:'You are not currently bound to the mailbox, please bind the mailbox after use',
		// 资产报表
		export:'Export the {exchange} exchange report',
		selectTimeTip:'Please select a time range',
		// 报警系统
		noTeleram:'There is no binding Telegram, to bind Telegram',
		startAlarm:'Confirmed to start Alert?',
		stopAlarm:'Some issues might be caused when the Alert teminated. Confirmed to terminate the alart system?',
		alarmAdvantage:'Enable the alarm system to ensure the security of your account.',
		alarmIsEnable:'The alarm system has been activated',
		alarmMainTip:'Activate the alarm system, monitor abnormal activities in real time, and ensure the security of your account.',
		// 续费开通
		discountTip1:'Daily promotion: 20% off for quarterly payments, 30% off for half-yearly payments, and 40% off for annual payments.',
		// discountTip2:'Limited-period activities: From November 1, 2022 to December 30, 2022, you can enjoy a 5% discount if you renew your wallet system.',
		renewalSuc:'Renewal success!',
		renewalInsufficient:'Failed to renew, your wallet balance is insufficient, please recharge!',
		setPackageSuc:'Package modification success!',
		setPackageInsufficient:'Failed to modify the package, your wallet balance is insufficient, please recharge!',
		txSeachErr:'The renewal hash has not been synchronized, please try again later!',
		txSeachErr2:'The hash has not been synchronized, please try again later!',
		notSupported:'Light-wallet does not support viewing!',
		withdrawRestrictions:'The minimum withdrawal amount is 50 USDT!',
		addressEmpty:'A valid withdraw address is required!',
		VerificationEmpty:'A valid verification code is required!',

		withdrawalSuc:'Your withdrawal application has been submitted, please check the status of the application in the withdrawal record',

		editSuc:'Edited success!',
		editErr:'Edited failed!',

		// 用户中心
		emailRepeat:'The new email is duplicate with the old email!',
		notBindEmail:'Please bind mailbox first!',
		ipClose:'The account may be at risk after IP authentication is disabled. Are you sure to disable it?',
		ipCloseTip:'【Note: IP authentication is not required within one week after this function is disabled, and it will be automatically enabled again after one week】',
		remove:'Telegram unbinding successful!',

		// req
		ipErr:'The currect IP is invalid. To prevent error, please login again!',
		unknownErr:'The system is busy, please try again later',//'Unknown Error',
		// 1003 1004
		update:'Update: ',
		// 2015
		// ipVerTip:'【Note: You can go to the user center to turn off IP authentication (not recommended)】',
		
		confirmAuthorize:'Confirm DEX wallet authorization token:',
		authorizeSuc1:'Authorization success!',
		authorizeSuc:'Authorization success, please try again!',
		mainAuthorizeErr:'【Note: please check the GAS fee in funds-wallet.】',
		authorizeErr:'【Note: wallet authorization failed due to insufficient gas. Please go to the manage wallet interface to confirm whether the failed wallet has sufficient gas.】',
		authorizeErr:'【Note: wallet authorization failed due to insufficient gas. Please confirm whether the failed wallet has sufficient gas.】',
		// 2109
		authorizeErr2:'【Note 1: Wallet authorization failed because of insufficient gas, please click allocation authorization or go to the  manage wallet interface to check the balance of wallet gas.】<br/>【Note 2: If you are a newly imported wallet or a newly opened wallet, please click direct authorization to confirm that the wallet gas is sufficient.】',
		// 2172
		authorizeErr3:'【Note: Wallet authorization failed because of insufficient gas, please click to allocate or go to the manage wallet interface to check the balance of wallet gas.】',
		
		// 2176 获取余额、充值地址
		renewErr:'Payment renewal wallet has not been generated, please contact customer service, please understand the inconvenience brought to you!',
		renewTip:'Please contact customer service for renewal subscription!',
		renewTip2:'Manual renewal is not supported at the moment. Please understand the inconvenience brought to you. Please contact the business customer service.',
		// renewTip3:'Your account can not use the renewal function, please contact the business customer service, please understand the inconvenience caused to you.',
		// renewTip4:'Your account can not modify the package, please contact the business customer service, please understand the inconvenience brought to you.',
		renewTip5:'Please understand the inconvenience caused to you. Please contact the business customer service.',
		renewTip6:'There is a recharge in progress, and your wallet is temporarily locked. Please try again later.You could visit the Recharge page to check the transaction records of your wallet.We apologize for the inconvenience caused. Please contact the customer service if you have any questions.',

		// 服务器错误
		serverErr:'Server busy, please try again later! ',
		// 服务器错误2 请求超时
		timeout:'Request timed out, Please check whether your network environment is normal and try again later!',
		// gpt响应错误
		gptErr:'Something went wrong while generating the response. Please try again later!',
	},

	// 账号列表
	account:{
		title:'Account List (Each exchange is limited to one account)',
		create:'Create an account',
		bind:'Bind a new account',
		toCreate:'No account yet, go to create...',
		newAccount:'New Account',
		accountName:'Remark',
		whiteList:'IP whitelist',
		reminder:'Note: ',
		dexReminder:'This wallet is suggested to be used as a transfer wallet of assets. Please deposit the appropriate amount of funds and allocate them to the trading-wallet created in the system as soon as possible',
		tip:"After obtaining the exchange's {key} and {secret}, ensure there are no spaces when entering them.",
		tip2_1:"If you don't know how to obtain API Key and other information, ",
		tip2_2:"please consult the exchange's customer support",
		editAcc:'Edit account',
		delAcc:'Delete account',
		isCreateAddTip:'You have already linked this exchange, and you can find it to update your account information.',
		keySecretSame:'The {name1} and {name2} you entered are duplicate. Please check again.',
		
		walletAddress:'Wallet address',
		privateKey:'Private Key',
		apiPWD:'API password',
		payPWD:'Payment password',
		needNewWallet: 'I need a new wallet',
		createWallet: 'Create Wallet',
		createWalletTip: 'To ensure fund security, we recommend choosing a wallet created by the platform.',
		hasWallet: 'I already have a wallet',
		importWallet: 'Import Wallet',
		importWalletTip1: 'If you choose to import an external wallet, please keep your private key safe.',
		importWalletTip2: 'The platform cannot be held responsible for any security issues with your funds.',
		
		confirmCreate: 'Create',
		mainWalletCreateSuc: 'Main wallet successfully created.',
		mainWalletCreateSub: 'The platform stores half of the private key; the other half is your responsibility to keep safe.',
		mainWalletCreateTip: 'Ensure you securely store your private key! Loss will result in permanent and irretrievable funds.',
		mainWalletCreateCheckTip: 'I confirm I have securely stored my private key and understand the risks of loss.',
	},

	// 手动操盘
	stocks:{
		update:'Auto Update',
		all:'All',
		current:'Current',
		tradingView:'K-Line Chart',
		openKline:'Open K-Line',
		closeKline:'Close K-Line',
		toolSelect:'Select Trading Tools',
		tools:'Trading Tools',//Quantification tools/trading tools 取决于要不要强调量化，意译的话我认为trading tool就可以
		order:'Manual Order',
		amount:'Total',
		realAmount:'Actual Funds Used for Pump and Dump',
		perAmount:'Total per Level',
		orderLimit:'Order Limit',
		exceedsLimit:'Order amount exceeds the limit',
		intervalTime:'Interval Time ',
		mainOrder:'Master Wallet Order',
		dexTransactionRes:'Confirm spending {volume}{token1} for {getMoney}{token2} exchange',
		check:'Price range of pending orders',//买单挂单价格范围Buy orders
		sale:'Price range of pending orders',//卖单挂单价格范围Sell orders
		priceRange:'Price range',
		quantity:'Order quantity',
		quantityRange:'Quantity range',
		total:'Total order volume',
		decimal:'Price Decimals',
		target:'Target price',
		walletsNumber:'Wallets number',
		volume:'Trading volume',
		floatingRange:'Floating range',
		strategy:'Trading strategies',
		strategyDefault:'Default',
		strategyCondition:'Condition',
		transactionType:'Transaction type',
		floatTip0:'The floating range is 0%. Wallets that place bulk orders will place an average number of orders',
		chain:'Chain Name',
		refresh:'Balance Refresh',
		wallets:'Wallets volume',
		totalQuantity:'Total quantity',
		totalCumulative:'Total cumulative',
		token:'Authorize token',
		tokenName:'Token Name',
		tokenNameTip:'Please enter the correct token name',
		orderManagement:'Orders manage',
		common:'Common Market',
		advanced:'Advanced Market',
		coins:'Tokens needed to sell, to achieve the target price',
		coinsMoney:'Tokens needed to buy, to achieve the target price',
		investors:'Other users amount',
		investorsTotal:'Other users cumulative',
		myself:'My amount',
		estimateVolume:'Estimate Trading Volume',
		estimatePrice:'Estimate Target Price',
		tradingVolume:'Trading Volume',
		orderMode:'Order Mode',
		normalMode:'Normal Mode',
		selfTradeMode:'Self-Trade Mode',
		
		robotMange:'Robots manage',

		buy:'Buy',
		sell:'Sell',
		
		accountAssets:'Account assets',
		mainAssets:'Funds wallet assets',
		subAssets:'Trading wallet assets',
		mainAssetsNFT:'Funds wallet nft assets',
		subAssetsNFT:'Trading wallet nft assets',

		// nft
		contractAddress:'Contract Address',
		date:'Created Date',
		items:'items',
		owners:'owners',
		totalVolume:'total volume',
		floor:'floor price',
		average:'average price',
		currency:'currency',
		more:'See more',
		less:'See less',
		
		// 选择盘口 常用盘口
		selectExchange:'Select Exchange',
		enterPair:'Enter Symbol',
	},
	// 交易钱包管理
	subManage:{
		totalAssets:'Total assets of all trading-wallets',
		useAssets:'Enabled assets in trading-wallets',
		notUseAssets:'Unenabled assets in trading-wallets',
		
		wallets:'Number of trading-wallets',
		totalAmount:'Total Amount',

		mainWallet:'Funds wallet',
		address:'Address',
		chain:'Chain',
		assets:'Assets',
		approveTokens:'Approve tokens',
		participate:'Transaction authority',
		transfer:'Transfer',
		transferAndAuthorize:'Transfer and Authorize',
		withdrawFunds:'Withdraw Funds',
		withdraw:'Withdraw',
		subWalletList:'Trading wallet list',
		enable:'Whether to enable',
		currentState:'Current Status',
		start:'Start',
		stop:'Stop',
		filterSearch:'Filter & Search',
		groupMange:'Group Management',
		walletGroup:'Wallet Group',
		groupFilter:'Group Filter',
		addGroup:'Add Group',
		groupNamePH:'Please enter group name',
		groupNameErr:'Group name cannot exceed 40 characters in length',
		groupNameExists:'The group name already exists',
		delGroup:'Delete Group',
		delGroupErr:'Please select the group you want to delete',
		delGroupTip:'Are you sure you want to delete the current group?<br/>After deletion, your wallet addresses will be moved to the default group.',
		replaceGroup:'Replace Group',
		currentGroup:'Current Group',
		// 导出
		subWalletExport:'Address Export',
		exportTip:'【Note: All open wallet addresses are exported here】',
		exportTotal:'There are {num} wallets in total',
		// 创建交易钱包
		createTip:'【Note: For the security of your assets and account, the wallet private key generated by the system cannot be exported】',
		createTip2:'【Note: After trading-wallets create, please disable the transaction authority of the funds-wallet in time if there is no need for the funds-wallet to involve in the transaction of robots and quantitative tools】',
		// 导入交易钱包
		key:'Please copy and paste the trading-wallet private key (paste on alternate lines)',
		currently:'Currently entered ',
		entry:' line',
		importTip:'【Note: For the security of your asset and account, the saved private key cannot be exported. Please back it up】',
		importTip2:'【Note: After trading-wallets import, please disable the transaction authority of the funds-wallet in time if there is no need for the funds-wallet to involve in the transaction of robots and quantitative tools】',
		// 分拨
		distributionStrategy:'Distribution Strategy',
		strategy:'Strategy',
		randomAllocate:'The random allocation',
		averageAllocate:'Average floating allocation',
		conditionalAllocate:'Conditional allocation',
		floatRange:'The floating range',
		walletBelow:'The wallet below',
		floatTip1:'The floating range is 0% and will be equally distributed to each trading-wallet',
		floatTip2:'If the floating range is greater than or equal to 80%, the system adjusts the allocation policy to random allocation',
		fundsSource:'funds Source',
		currentProgress:'Current Progress',
		allocating:'Allocating…',
		collecting:'Collecting…',
		completed:'Submission completed',
		skip:'Skip wallet',
		allocatFailed:'Allocation failed',
		collectFailed:'Consolidation failed',
		// 资金划转
		transferTip:'【Note: For the security of your assets, the target address of fund transfer can only be the funds-wallet or trading-wallet of your account】',
		single:'Single Wallet Transfer',
		multiple:'Wallet Allocation',
		targetAddressSame:'The destination address is the same wallet, and the same wallet cannot be transferred',
		safeVer:'Security verification',
		safeVerTip:'【Note: Your current operation involves a large amount of money. To ensure the security of funds, you need to verify your email address】',

		// 来源
		enabled:'Current Sources Enabled',
		create:'System Creates',
		import:'External Import',
		source:'trading-wallets category',
		onlyCreate:'System Created',
		onlyImport:'Externally Imported',
		allOpen:'Batch Enable',
		allClose:'Batch Disable',
		selectDelete:'Delete Selected Items',
		allOpenTip: 'Are you sure you want to enable the selected wallets?',
		allCloseTip: 'Are you sure you want to disable the selected wallets?',
		selectDeleteTip:'Are you sure you want to delete the currently selected wallet?',
		selectedNum:'Selected {num}',
		searchWallet:'Search wallet',

		// 交易限制
		transactionRestrictionModify:'Modify Transaction Limit',
		transactionRestriction:'Transaction Limit',
		unlimited:'Unlimited',
		buyOnly:'Buy only',
		sellOnly:'Sell only',
		allUnlimitedTip:"Are you sure to change the transaction limit to 'unlimited' for the currently selected wallet address?",
		allBuyOnlyTip:"Are you sure to change the transaction limit to 'Buy only' for the currently selected wallet address?",
		allSellOnlyTip:"Are you sure to change the transaction limit to 'Sell only' for the currently selected wallet address?",
		limitTip:'Transaction restriction: Unlimited (Selected wallet can place both buy and sell orders)、Only buy (Selected wallet can only place buy orders.)、Only sell (Selected wallet can only place sell orders.)',
		
		// NFT
		openBtnTip:'Click to display NFT assets below the list',
		openBtn:'Open',
		closeBtn:'Close',
		copyAddress:'Copy address to clipboard',
	},

	robotLevel:[
		'Basic',
		'Advanced',
		'Expert',
	],
	robot:{
		list:{
			// 1
			buy_sell:{
				name:'Range Trading',//'Sell-high and buy-low robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The robot buys low and sells high in within the price range provided. Once the price is higher or lower than the range stated, a sell or buy order will be executed appropriately.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Match Opposing Quantity: After selecting "Match Opposing Quantity", the "Quantity" field will be disabled. When placing an order, the robot will determine its own trading volume based on the retail order volume in the order book.
					<br/><br/>Maximum Position Size: If the current position size is larger than that stated here, the robot will stop executing buy orders.
					<br/><br/>Minimum Position Size: If the current position size is smaller than that stated here, the robot will stop executing sell orders.
					<br/><br/>Working Conditions: When "Working Conditions | Only work when the market is stuck" is selected, the robot will only operate when the market price is obstructed, preventing normal trading. If this option is not selected, the robot will start trading as soon as the price reaches the set range.
					<br/><br/>Limit Order: After selecting "Limit Order", the robot will pre-place buy and sell orders at the designated price points to ensure quick execution when the price reaches the set value, thereby increasing the strategy's success rate.
					<br/><br/>Allow self-trade: After selecting "Allow self-trade", the robot will still execute the trade even if the order that triggers the trade condition is placed by the same account, optimizing the position of buy and sell orders in the account.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start operation within 10 minutes of activating it while clicking the stop button will stop the robot immediately.</p>
				`,
			},
			// 2
			iceberg:{
				name:'Batch Selling',//'Dividely sell robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The robot sells a large quantity of an asset in small batches. </p>
				<br/><p style="color: #9198AA;">
				According to the minimum selling price indicated, the robot will execute sell orders in intervals (seconds) as stated. The actual quantity of each order is a random value within 30% of the indicated quantity to ensure that overall size is hidden.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Clicking the start button activates the robot and when either the asset balance is insufficient or the price is below the floor price, the robot will stop.</p>
				`,
			},
			// 3
			banzhuan:{
				name:'Price Hedging',//'Arbitrage Trading',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Does arbitrage trading across the selected exchange.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Exchange: Select the exchange to conduct the arbitrage trading.
					<br/><br/>Target Symbol: The target symbol to follow.
					<br/><br/>Current Service Fee: Enter the current fee incurred by users for trades.
					<br/><br/>Target Service Fee: Enter the target fee to be incurred by users for trades.Quantity Precision
					<br/><br/>Price Decimals: Enter the number of decimal places allowed for price.
					<br/><br/>Quantity Precision: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked.</p>
				`,
			},
			// 4
			wangge:{
				name:'Martingale',//'Martingale strategy quantify robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>At every configured level, Martingale will increase the position in volume according to the multiple indicated and reenter, averaging price.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>First Position Size: the opening amount of the first order, in {0}

					<br/><br/>Times of Margin Calls: After opening a position, the total number of times to increase the position.(excluding open position)

					<br/><br/>Profit Taken Range: The unit is %.
					It is the percentage that the latest price is higher than the current average position price. When this value is reached, the profit will not be taken immediately, and the profit will only be taken when the price callbacks by a certain percentage.

					<br/><br/>Profit Taken Callback Ratio: How much the price callback to sell positions after reaching the profit taken ratio.

					<br/><br/>Price Difference of Margin Calls: The unit is %, 
					calculated from the last purchase price. It determines how much the market continues to fall the robot will increase its position.
					When this value is reached, the position will not increase immediately unless the price rebound by a certain percentage.

					<br/><br/>Position Addtion Callback Ratio:: the unit is %, after reaching the increase position ratio, how much the price rebounds before increasing position.

					<br/><br/>Double The First Order: after the first order is purchased, if the price fluctuates within 1%, an additional purchase will be made. The amount is equal to the amount of the first order and is not included in the number of position additions.

					<br/><br/>Multiple of Position Addition: how many times the amount of each position addition is the previous one.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked. Once stopped, all pending orders will be cancelled.</p>
				`,
			},
			// 5
			grid:{
				name:'Grid',//'Grid Startegy robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span> At every configured level, Grid will increase the position in volume according to the configured amount and reenter, averaging price down.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>First Position Size: The amount of first buy order.
					<br/><br/>Number of Grids: maximum number of purchases (including the first time)
					<br/><br/>Profit Taken Ratio: The price interal of each profit taken order after each purchase.
					<br/><br/>Position Addition Interval(PAI): the unit is %. The price interval to place the next buy order after purchasing.
					<br/><br/>Increase Ratio of PAI: The gradient of the PAl, if number filled in is X greater than 1, the next Margin Interval will be magnified by X times.
					<br/><br/>Increase Ratio of Position Addition Amount: the gradient of the amount placed for each position addition order. 
					If the number filled in is Y greater than 1, the next position addition amount will be multiplied by Y times.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked. Once stopped, all pending orders will be cancelled.</p>
				`,
			},
			// 6
			shua:{
				name:'Volume Generating',//'Match order robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Creates trading volume and increases market activity.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Volume Range: The quantity of each knock-on transaction, which is within a range, such as 100 to 300.
					<br/><br/>Orders Frequency(Per Minute): Represents the range of knock-on transactions per minute, such as 0 to 1.
					<br/><br/>Price Decimals: Represents the decimal precision of the price. If the currency price is 0.000366, enter 6.
					<br/><br/>Number Decimals: Represents the decimal precision of the order quantity. If you want the order quantity to be accurate to three decimal places, enter 3.
					<br/><br/>Clear Orders: If a robot's orders collide with retail traders' orders, it may result in leftover wash trades. If selected, it will clear the robot's remaining orders in the order book, which helps in asset protection and anti-arbitrage by removing orders lingering in the order book.
					<br/><br/>Safe Mode: When malicious interference and withdrawal occur in the market, this mode will help you combat arbitrage behavior with a very comprehensive set of protection modules.
					<br/><br/>Sell Order First: When enabled, wash trading will first place a sell order and then a buy order.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked. If the market enters a consolidation phase, the robot will pause activities.</p>
				`,
			},
			// 7
			budan_new:{
				name:'Orderbook Management',//'Orders management robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span> Controls the spread and increases the market depth. Improving liquidity of the market.
				<br/>The order book management bot comes with a grid defense mechanism. After a deep order is filled, the bot will place a take-profit order based on the grid trading strategy.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Buy Order Volume: Volume of each buy order. Actual volume will be within a 30% deviation of volume stated.
					<br/><br/>Sell Order Volume: Volume of each sell order. Actual volume will be within a 30% deviation of volume stated.
					<br/><br/>Spread Size: Percentage difference between best bid and best ask.
					<br/><br/>Spread per Level: Percentage change in price between each level.
					<br/><br/>Price Decimals: Decimal precision of price.
					<br/><br/>Number of Orders: Total number of orders used to support the price range. The default value is 20.
					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked.</p>
				`,
			},
			// 8
			hupan_buy:{
				name:'Bid Guardian',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Creates a bid guardian at the price range indicated.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Bid Gap: Range of price to support.
					<br/><br/>Total Amount: Total amount of funds for supporting price at the price range stated.
					<br/><br/>Number of Orders: Total number of orders to execute to support price.
					<br/><br/>Price Decimals: Decimal precision of price.
					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked or stop once it has fulfilled all of the orders.</p>
				`,
			},
			// 9
			hupan_sell:{
				name:'Ask Guardian',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Creates a ask guardian at the price range indicated.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Ask Gap: Range of price to resist.
					<br/><br/>Total Amount: Total amount of funds for resisting price at the price range stated.
					<br/><br/>Number of Orders: Total number of orders to execute to resist price.
					<br/><br/>Price Decimals: Decimal precision of price.
					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked or stop once it has fulfilled all of the orders.</p>
				`,
			},
			// 10
			push:{
				name:'Trend Setting',//'Trend push robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Trend push is used for price pumping or dumping. Once the robot is activated, it will push the price towards the "target price" within the set time. When the price reaches the target price or the end time is reached, the robot will stop its operation. If there is retail investor interference, the robot will automatically adjust the pushing speed.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Total Consumption Budget: Total amount committed to enforcing trend.
					<br/><br/>End Time: Set a time for the robot to reach the target price
					<br/><br/>Target Price: Desired price for asset.
					<br/><br/>Price Decimals: Decimal precision of price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked or stop once the target price has been met.</p>
				`,
			},
			// 11
			hugevol:{
				name:'Huge Volume',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Within the set time, the robot will place a large order on the sell side of the market and quickly rebuy it.This function uses to generate considerable trading volume in a short time. Meanwhile, it also leads retail investors’ attention to the support and selling intentions at specific price zone.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Token Volume: The volume of transactions that need to be made by this robot per minute
					<br/><br/>Working Hours: A few minutes
					<br/><br/>Consumption Budget: The budget of capital consumption caused by transaction with users
					<br/><br/>Price Decimals: Decimal precision of price.
					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately. </p>
				`
			},
			// 12
			draw:{
				name:'Charting',//'Candlestick chart maintainer robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Pushes prices up or down to develop the desired trend. Pending orders will be placed according to the frequency and price of orders.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Upper Limit: Highest price allowed for charting.

					<br/><br/>Lower Limit: Lowest price allowed for charting.

					<br/><br/>Spread Size: Percentage difference between best bid and best ask.

					<br/><br/>Spread per Level: Percentage change in price between each price point.

					<br/><br/>Buy Order Volume: Number of buy orders to be maintained at each price point. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Sell Order Volume: Number of sell orders to be maintained at each price point. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Max Quantity per Order: During the process of pushing the price to the next price node, if retail orders block the movement, the system checks whether the total volume required to be executed exceeds that threshold. If it does not exceed the threshold, the robot will consume this batch of retail orders and continue with the price fluctuations; otherwise, it will shorten the drawing distance until it is less than the maximum volume that can be consumed in a single operation.

					<br/><br/>Price Decimals: Decimal precision of price.

					<br/><br/>Total Daily Amount of Orders: Maximum number of orders allowed in a day.

					<br/><br/>Index Follow: When this function is enabled, the charting robot will work with reference to the trends of the main market (like BTC, ETH, etc).

					<br/><br/>Price Sequence: The robot will create an ideal price fluctuation curve based on the set prices and times.

					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately. </p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Risk control: </span>If other users participate in the transaction, causing the price to break out of the set range, the robot will automatically stop working.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Notice: </span>When the robot encounters a large order from the user account during running, The robot will identify it as a resistance zone set by the user and will not trade with these orders. Definition of large order: (order amount > 5 times of set quantity of buy and sell orders)</p>
				`,
			},
			// 13
			real_genpan:{
				name:'Market Syncing (AltCoins)',//'Trading pair follower robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function:  </span>Imitates the market movement of the token from another exchange. This imitation encompasses the Volume Generating and Orderbook Management functions. Thus, when Market Syncing is activated,  these 2 robots working on the same token will stop.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Mode: In standard mode, the robot will track according to the target disk price. In the hedging mode, the robot will realize the synchronization of price and liquidity at the same time through cross-hedging, which can effectively prevent cross-arbitrage.
					<br/><br/>Target Exchange: The exchange that the pair is listed on to Market Sync.
					
					<br/><br/>Target Symbol: The target symbol to follow.
					<br/><br/>Price Ratio: Scales the price movement of the followed trading pair. The parameter supports any number greater than 0. If you fill in 1%, the price followed by the current token will be 1% of the target trading pair price. If you want the current token to run exactly at the target trading pair price, please set it to 100%.If the currency is the same but the trading pairs are different, and you want the robot to automatically obtain the price ratio of the two trading pairs, please enter 100%.

					<br/><br/>Spread Size: Percentage difference between best bid and best ask.

					<br/><br/>Spread per Level: Percentage change in price between each price point.

					<br/><br/>Buy Order Volume: Number of buy orders to be maintained at each price point. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Sell Order Volume: Number of sell orders to be maintained at each price point. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Max Quantity per Order: Maximum volume allowed per order.

					<br/><br/>Price Decimals: Decimal precision of price.

					<br/><br/>Total Daily Amount of Orders: Maximum number of orders allowed in a day.
					
					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked.</p>
				`,
			},
			// 14
			kongpan:{
				name:'Advanced OrderBook Management',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Expert-level Order Book Management Robot, capable of managing depth orders across different quantity ranges simultaneously.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Amount: The maximum budget to the target price, if the consumption exceeds the budget, the robot will automatically stop.

					<br/><br/>Benchmark Price: If there are no orders in the order book, initialize the order book based on the benchmark price.

					<br/><br/>Spread Size: Spread Percentage required to keep between first buy order and first sell order. (here needs to fill an absolute value instead of a percentage)

					<br/><br/>Orders per Level (bid-ask): Quantity of orders per price point. This function of this robot supports customization, and there are three scales of orders for choosing to pending out, which are small, medium and large orders.

					<br/><br/>Spread per Level: Absolute value of price spread required to keep between each price point of buy and sell orders.

					<br/><br/>Number of Levels: The total number of price point required to maintain. 

					<br/><br/>Price Decimals: Fill in the number of decimal places in the price.
					<br/><br/>Number Decimals: Largest Trade Volume allowed.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately. </p>
				`,
			},
			real_genpan_quick:{
				name:'Market Syncing (Main Coins)',//'Trading pair follower robot (For mainstream tokens)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It allows the current token to fluctuate with the price of the same-named token of another exchange. Since it includes matching order and orders management functions. When it is activated, The volume generating robot and orderbook management robot at the same trading pair will automatically terminate. It has own cross-platform hedging function. Working conditions: The market must have depth, or at least one pending order.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Exchange: The exchange that the pair is listed on to Market Sync.

					<br/><br/>Spread Size: Percentage difference between best bid and best ask.

					<br/><br/>Spread per Level: Percentage change in price between each price point.

					<br/><br/>Quantity of Buy Orders: Number of buy orders to be maintained at each price point. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Quantity of Sell Orders: Number of sell orders to be maintained at each price point. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Price Decimals: Decimal precision of price.

					<br/><br/>Total Daily Amount of Orders: Maximum number of orders allowed in a day.
					<br/><br/>Number Decimals: Largest Trade Volume allowed.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			open_cex:{
				name:'OpenBell Trader',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Used to set the opening price, highest price, and then fall back to the predetermined consolidation price at the moment of market opening.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>

					<br/><br/>Opening Time: The specific point in time when trading begins.(Local Time)

					<br/><br/>Opening Price: The first price point at market open.

					<br/><br/>Highest Price: The highest price reached immediately after the market opens.

					<br/><br/>Fallback Price: Target price after the highest price falls.

					<br/><br/>Order Volume During Rise and Fall: The order quantity for the price increase and fall transactions.

					<br/><br/>Price Decimals: Decimal precision of the price. Please fill in this parameter strictly according to the information provided by the exchange. If the value you enter is not the same as the exchange's value, it may lead to order errors or failure to place orders. If the order price is 0.000366, fill in 6.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`
			},
			shua_dex:{
				name:'DEX Charting',//'DEX candlestick chart maintainer robot',
				// , and the maximum is 600 seconds
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used to make sideways in a set price range and create trading volume. Furthermore,  increase the activity of the market and maintain the trend of the candlestick chart of the DEX.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price Range: Fill in the numbers from low to high to control the line maintenance range. When the set range is exceeded, the robot will try its best to run within the set range.
					<br/><br/>Volume per Deal: Fill in the numbers from low to high, the trading volume of each transaction will be a random range, the range can be set to a larger range to ensure that the transaction record looks like real users transaction.
					<br/><br/>Interval Time: The time interval for each transaction. Due to the high cost of dex service fees and gas fees, it is recommended to fill in at least 30 seconds.
					<br/><br/>Operation Mode: Special modes that can be used for configuring robots. The difference between automatic allocation mode and normal mode is that it will automatically allocate the funds in the account.
					<br/><br/>Batch Order Mode: The robot will force the use of multiple wallets to execute each order based on the set conditions.
					<br/><br/>Trend Direction: When the charting robot operates, it will defaultly refer to the price trend direction of the overall market.  This parameter can further adjust the direction of the price trend. 
					<br/><br/>Gas Multiplier: Adjust the multiplier of operational gas fees to optimize transaction speed and success rate, defaulting to 1x.
					<br/><br/>Wallet Grouping: By selecting a wallet grouping, only the wallets in the chosen group will participate in placing orders.

				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. 
				If transaction fails due to any reasons or the price difference between the buy and sell orders is extremely small which caused market stuck, the robot will pause work.</p>
				`,
			},
			real_genpan_dex:{
				name:'DEX Market Syncing (AltCoins)',//'DEX trading pair follower robot',
				// , and the maximum is 600
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used in DEXs to control price follow the price of the CEX.The trading pair being followed can be any trading pair. Please note that control the price scaling parameters to ensure that the price operates within the expected range.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Exchange: The exchange of the target trading pair that needs to be followed.
					<br/><br/>Target Symbol: The target symbol to follow.
					<br/><br/>Price Scaling: Scales the price movement of the followed trading pair. The parameter supports any number greater than 0. If you fill in 1%, the price followed by the current token will be 1% of the target trading pair price. If you want the current token to run exactly at the target trading pair price, please set it to 100%.
					<br/><br/>Interval time: The interval time between each execution to follow price. The unit is seconds. It is recommended to be no less than 30 seconds,  because service fees at DEX and gas fees are high. it is not suitable for high-frequency trading.
					<br/><br/>Maximum Volume per Order: The maximum limit of each execution to follow price. If this volume is exceeded, it means that following the price needs to consume too many tokens. The robot will terminate working and report an error in the log. This parameter is used to secure your assets in unexpected situations.
					<br/><br/>Wallet Grouping: By selecting a wallet grouping, only the wallets in the chosen group will participate in placing orders.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			buy_sell_dex:{
				name:'DEX Range Trading',//'DEX sell-high and buy-low robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Used to continuously buy low and sell high in a sideway markets. At the same time, it can control the price to prevent the price from being too high or too low, and repeatedly arbitrage in a sideways move.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price Above: When the price higher than the set price, the robot will sell.
					<br/><br/>Price Below: When the price lower than the set price, the robot will buy.
					<br/><br/>Order Interval: It determines how often this robot works once.
					Please note that with higher the frequency, there will be higher the transaction cost and stricter control of the price. 
					Please configure it according to your requirements. It is recommended to work every 30 seconds or more.
					<br/><br/>Order Quantity: The volume of each order after the set price is triggered. In order to make the transaction record look normal, the actual order volume will be a random value within the range of 50% above and below the set volume.
					<br/><br/>Maximum Position Size: Assets security parameters. When the robot detects that the balance of the token is greater than or equal to the set maximum position, the robot will stop buying.
					<br/><br/>Minimum Position Size: Assets security parameters. When the robot detects that the balance of the token is lower than or equal to the set minimum position, the robot will stop selling.
					<br/><br/>Gas Multiplier: Adjust the multiplier of operational gas fees to optimize transaction speed and success rate, defaulting to 1x.
					<br/><br/>Wallet Grouping: By selecting a wallet grouping, only the wallets in the chosen group will participate in placing orders.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			// 19
			frontrun_dex:{
				name:'Dex Scheduled Buy',//'DEX Snap Up',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The robot will place an order of a set amount at the scheduled time. Due to significant price fluctuations, the purchase price may be unfavorable or the purchase may fail.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Opening Time: The specific point in time when trading begins.
					<br/><br/>Highest Bid Price: Acceptable Highest Bid Price.
					<br/><br/>Gas: The fee required to execute a transaction.
					<br/><br/>Transaction Amount: The volume of funds involved in a transaction.
					<br/><br/>Wallet Grouping: By selecting a wallet grouping, only the wallets in the chosen group will participate in placing orders.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			snipe_dex:{
				name:'DEX New Token Sniper',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>For newly created symbol in DEX, the initial price is always very low. So there is a great opportunity to rise. This robot can monitor the creation of the target symbol and buys it as soon as it is created.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Contract: fill in the contract address of the target token.
					<br/><br/>Order Amount: The buying amount after the target trading pair is found to be created.
					<br/><br/>Earnings Multiple: The preset profit multiple of sniping. The system automatically sells when the profit reaches the set multiple.
					<br/><br/>Listing Time: preset the listing time of the target token, and the system will automatically start sniping when the time comes.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			// 21
			banzhuan_dex:{
				name:'Arbitrage From DEX To CEX',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Help user to arbitrage from DEX to CEX.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Exchange: Select which exchange to arbitrage with the current exchange.
					<br/><br/>Current Service Fee: unit is %. if the current service fee is 0.1%, then fill in with 0.1.
					<br/><br/>Target Service Fee: unit is %. if the target service fee is 0.1%, then fill in with 0.1.
					<br/><br/>Price Decimals: fill in the number of decimal places in the price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			
			shua_nft:{
				name:'NFT Charting',
				// , and the maximum is 600 seconds
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used to make sideways in a set price range and create trading volume. Furthermore,  increase the activity of the market and maintain the trend of the candlestick chart.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price Range: Fill in the numbers from low to high to control the line maintenance range. When the set range is exceeded, the robot will try its best to run within the set range.
					<br/><br/>Volume per Deal: Fill in the numbers from low to high, the trading volume of each transaction will be a random range, the range can be set to a larger range to ensure that the transaction record looks like real users transaction.
					<br/><br/>Interval Time: The time interval for each transaction. Due to the high cost of service fees and gas fees, it is recommended to fill in at least 30 seconds.
					<br/><br/>Trend Direction: When the charting robot operates, it will defaultly refer to the price trend direction of the overall market.  This parameter can further adjust the direction of the price trend. 
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. 
				If transaction fails due to any reasons or the price difference between the buy and sell orders is extremely small which caused market stuck, the robot will pause work.</p>
				`,
			},
			buy_sell_nft:{
				name:'NFT Range Trading',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Used to continuously buy low and sell high in a sideway markets. At the same time, it can control the price to prevent the price from being too high or too low, and repeatedly arbitrage in a sideways move.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>When the price higher than: When the price higher than the set price, the robot will sell.
					<br/><br/>When the price lower than: When the price lower than the set price, the robot will buy.
					<br/><br/>Time interval: It determines how often this robot works once.
					Please note that with higher the frequency, there will be higher the transaction cost and stricter control of the price. 
					Please configure it according to your requirements. It is recommended to work every 30 seconds or more.
					<br/><br/>Volume per order: The volume of each order after the set price is triggered. In order to make the transaction record look normal, the actual order volume will be a random value within the range of 50% above and below the set volume.
					<br/><br/>Maximum position: Assets security parameters. When the robot detects that the balance of the token is greater than or equal to the set maximum position, the robot will stop buying.
					<br/><br/>Minimum position: Assets security parameters. When the robot detects that the balance of the token is lower than or equal to the set minimum position, the robot will stop selling.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},

			// 24
			defence:{
				name: 'Defence',
				html: `
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span> This robot is used for defensive counterattacks when there are arbitrage teams in the market, and the arbitrage situation is extremely serious. Please note that this robot will clear all previous pending orders in the market.</p>
				<br/>
				<p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Minimum Order Quantity: Fill in the minimum order quantity set by the exchange.
					<br/><br/>Stuck Quantity: Enter the quantity of stuck orders. It can be set as small as possible, for example, 1. This number can be smaller than the minimum order quantity.
					<br/><br/>Quantity per Execution: The quantity attempted to be placed for each card position success.
					<br/><br/>Coin Upper Limit: When the asset of the coin is greater than or equal to this number, the robot will enter the selling state.
					<br/><br/>Coin Lower Limit: When the asset of the coin is less than or equal to this number, the robot will enter the buying state.
					<br/><br/>Price Decimals: Decimal point precision of the trading pair's price.
					<br/><br/>Number Decimals: Precision of the order quantity for the trading pair.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},
			// 25
			iceberg_dex:{
				name:'DEX Batch Selling',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The robot sells a large quantity of an asset in small batches. </p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Floor Price: Used to set the minimum price threshold for sell operations. When the market price is below this price, the robot will not execute sell operations.
					<br/><br/>Interval: The time interval between two sell operations, in seconds.
					<br/><br/>Quantity: The planned quantity to be sold in each sell operation. To maintain the stealth of the selling behavior, the actual order quantity will randomly fluctuate within ±10% of this set value.
<br/><br/>Count: The total number of sell operations for this sell task.
					<br/><br/>Count: The total number of sell operations for this sell task.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Clicking the start button activates the robot and when either the asset balance is insufficient or the price is below the floor price, the robot will stop.</p>
				`,
			},
			// 26
			shua_dex2:{
				name:'Dex Volume Generator',
				// , and the maximum is 600 seconds
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Controlling a large number of wallet wash trading, buying and selling orders occur simultaneously, increasing the number of active wallets and trading volume for the trading pair.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price Range: Fill in the numbers from low to high to control the line maintenance range. When the set range is exceeded, the robot will try its best to run within the set range.
					<br/><br/>Volume per Deal: Fill in the numbers from low to high, the trading volume of each transaction will be a random range, the range can be set to a larger range to ensure that the transaction record looks like real users transaction.
					<br/><br/>High-frequency mode: The bot will increase trading volume and market activity through fast and frequent buy and sell transactions.
					<br/><br/>Interval Time: The time interval for each transaction. Due to the high cost of dex service fees and gas fees, it is recommended to fill in at least 30 seconds.
					<br/><br/>Operation Mode: Special modes that can be used for configuring robots, among which the batch order placement mode fluctuates according to the set price sequence. The difference between automatic allocation mode and normal mode is that it will automatically allocate the funds in the account.
					<br/><br/>New Wallet Mode: The robot will create and use multiple new wallet addresses to increase the number of maker orders in trades, boosting market activity.(Currently, this mode only supports the Solana chain)
					<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;● When starting: After the robot is activated, it will enter a 10-minute initialization phase. Only after the initialization is complete can formal trading begin.
					<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;● When stopping: After the robot stops, it will automatically perform a consolidation operation. You can check the consolidation progress in the logs. The robot can only be restarted after the consolidation is complete.
					<br/><br/>Gas Multiplier: Adjust the multiplier of operational gas fees to optimize transaction speed and success rate, defaulting to 1x.
					<br/><br/>Wallet Grouping: By selecting a wallet grouping, only the wallets in the chosen group will participate in placing orders.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start condition: </span>There must be at least one order each for the buy and sell sides.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. 
				If transaction fails due to any reasons or the price difference between the buy and sell orders is extremely small which caused market stuck, the robot will pause work.</p>
				`,
			},
			pump_sniper:{
				name: 'PumpFun Sniper',
				html: `
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Use Case:</span> Set the total buy amount and monitor the token issuance actions from a specific address to perform buy operations.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Parameter Explanation:</span>
					<br/><br/>Buy Group: After selecting a buy group, only wallets in the selected group will participate in placing orders.
					<br/><br/>Total Buy Amount: The total buy amount for all wallets in the buy group that the robot expects to perform.
					<br/><br/>Monitor Address: The robot will monitor the PUMP token issuance actions from this address, and once triggered, the robot will immediately perform the buy operation.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start and Stop:</span> Click the start button to begin the robot's operation immediately.</p>
				`,
			},
			pump_sellout:{
				name: 'PUMP Sell Out',
				html: `
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Use Case:</span> By setting the wallet group and minimum selling price, the robot will perform liquidation when the market reaches the pre-set selling price.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Parameter Explanation:</span>
					<br/><br/>Wallet Group: Only the wallets in the selected group will participate in the liquidation.
					<br/><br/>Total Buy Amount: The total buy amount for all wallets in the buy group that the robot expects to perform.
					<br/><br/>Min Sell Price: The robot will start the liquidation process when the market price reaches or exceeds this price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start and Stop:</span> Click the start button to begin the robot's operation immediately.</p>
				`,
			},
			
		},
		showRunning:'Show Running Only',
		addrobot:'Add Robot',
		log:'Robot log',
		// 1
		higherthan:' Price Above ',
		sellout:' sell',
		below:' Price Below ',
		purchase:' buy',
		interval:'Order Interval ',
		second:' second',
		per:'Order Quantity ',
		opponents:'Match Opposing Quantity',
		maximum:'Maximum Position Size ',
		maximumPH1:'Maximum token balance',
		maximumTip:'Maximum position limit when buying',
		minimum:'Minimum Position Size ',
		minimumPH1:'Minimum token balance',
		minimumTip:'Minimum position limit when selling',
		working:'Working Conditions ',
		chuck:'Only work when the market is stuck',
		limitOrder:'Limit Order',
		tradeSelfOrder:"Allow self-trade",
		estimatedVol24:'Estimated 24h Trading Volume',
		estimatedAmount:'Estimated Total Trading Amount',
		buySellBotTip:'Stop buying when the account balance exceeds the maximum position, and stop selling when it falls below the minimum position.',
		// 2
		floorPrice:'Floor Price ',
		interval2:'Interval ',
		interval2ph:'Interval ',
		quantityPerTime:'Quantity ',
		quantityPerTimeph:'Quantity ',
		frequency:'Count ',
		frequencyPH:'Number of Times ',
		// 3
		targetExchange:'Target Exchange ',
		currentCharge:'Current Service Fee ',
		targetCharge:'Target Service Fee ',
		priceDecimal:'Price Decimals ',
		priceDecimalPH:'Please enter the decimal precision for the price. If the coin price is 0.000366, please enter 6.',
		quantityDecimal:'Quantity Precision ',
		quantityDecimalPH:'Please enter the decimal precision for the order quantity. If you want the order quantity to be precise to three decimal places, please enter 3.',
		banzhuanBotTip:'{robot} operates when a price difference occurs between two trading pairs and the difference exceeds the fees. It sells on the higher-priced side and buys on the lower-priced side.',
		// 4
		openingAmount:'First Position Size ',
		repTimes:'Times of Margin Calls ',
		repTimesPH:'Times of Margin Calls ',
		interferenceRange:'Profit Taken Range ',
		repDifferences:'Price Difference of Margin Calls ',
		ci:'Times',
		recommend:'recommend',

		stopBackRatio:'Profit Taken Callback Ratio',
		addBackRatio:'Position Addtion Callback Ratio',
		firstDouble:'Double The First Order',
		addMultiple:'Multiple of Position Addition',

		firstAmount:'First Position Size',
		// addNum:'Times of Position addition',
		stopRatio:'Profit Taken Ratio',
		// declineRatio:'Increase positions when the price drops by',
		// 5
		priceRange:'Price Range ',
		priceRangePH1:'Lowest price',
		priceRangePH2:'Highest price',
		grids:'Number of Grids ',
		perGear:'Quantity Per Price Point ',
		maintain:'Buy Order Maintains ',
		stopPrice:'Stop Price ',
		// more:'Go long',
		// short:'Go short',

		addInterval:'PAI',//全称Position addition interval
		addIntervalIncrease:'Increase Ratio of PAI',
		addAmountIncrease:'Increase Ratio of Position Addition Amount',
		// 6 shua
		counterQuantity:'Volume Range ',
		perMinute:'Orders Frequency (Per Minute) ',
		clearOrder:'Clear Orders',
		advancedProtection:'Safe Mode',
		sellOrderFirst:'Sell Order First',
		latestTransactionPriceErr:'Due to the instability of the exchange API, the latest transaction price cannot be retrieved, and the estimated total trading amount is temporarily unavailable. However, this does not affect the normal operation of the robot.',
		securityCloseLabel:'Safety modules disabled',
		securityCloseTip:'The current frequency is set to exceed 60 actions per minute (entering high-speed mode), and all safety modules will be automatically disabled.',
		// 7
		orderPerNum:'Buy Order Volume ',
		orderPerNumPH:'Please enter the buy order quantity per level for the order book.',
		sellPerNum:'Sell Order Volume ',
		sellPerNumPH:'Please enter the sell order quantity per level for the order book.',
		difference:'Spread Size ',
		differencePH:'Spread Size ',
		perDifference:'Spread per Level ',
		perDifferencePH1:'Set half of the Spread Size',
		perDifferencePH:'Spread per Level ',
		maintenance:'Number of Orders ',
		maintenanceVal:'Buy and sell {num} each',
		buyNumRang:'Buy order quantity range',
		sellNumRang:'Sell order quantity range',
		estimatedFunds:'Estimated funds to be used',
		buyOrder:'Buy orders',
		sellOrder:'Sell orders',
		pankouManageTip:'The bot will automatically manage orders within this quantity range. If your orders fall within this range, they may be canceled.',
		pankouReadFail:'Unable to read the market data, the robot cannot work properly',
		positionTip:'The highest buy orders will be placed at {buyPriceTarget}, and the lowest sell orders will be placed at {sellPriceTarget}<br/><br/>Confirmed to start the robot?',
		// 8 9
		buyPriceRange:'Bid Gap',
		sellPriceRange:'Ask Gap',
		protective:'Total Amount ',
		protectivePH:'Total Amount ',
		gear:'Number of Orders ',
		gearPH:'Number of Orders ',
		priceTickPH8:'Price Decimals',
		numTickPH8:'Number Decimals',
		hupanBotTip:'The bot will stop working once all the protection orders you set have been fully executed.',
		// 10
		buyBtn:'Buy',
		sellBtn:'Sell',
		plan:'Total Consumption Budget ',
		singleBudget:'Single Budget',
		// intervalRange:'Range of Intervals ',
		random:' second',//random second
		// perInterval:'Price Change ',//Price interval for each push
		endTime:'End Time',
		startPrice:'Start Price ',
		targetPrice:'Target Price ',
		pushBotTip:'If the budget is exceeded during operation, the bot will stop early.',
		// 11
		tradingVolume:'Token Volume',
		workingHours:'Working Hours',
		splitBudget:'Consumption Budget',
		minute:'minute',
		minutes:'minute(s)',
		// 12
		upperLimit:'Upper Limit ',
		lowerLimit:'Lower Limit ',
		buy:'Quantity of Buy Orders ',
		offers:'Quantity of Sell Orders ',
		singleMax:'Max Quantity per Order ',
		singleMaxPH:'Max Quantity per Order ',
		swiping:'Total Daily Amount of Orders ',
		swipingPH:'Total Daily Amount of Orders ',
		indexFollow:'Index Follow',
		limitTip:'The upper limit of the range must be greater than or equal to the lower limit.',
		drawBotTip:'After the bot starts, if the latest transaction price exceeds the set price, the drawing module will stop, while other modules will continue to operate.',
		// 13
		standard:'Standard Mode',
		rebalance:'Hedging Mode',
		targetDisk:'Target Exchange ',
		scaling2:'Price Ratio',
		realGenpanBotTip:'{robot} follows the order book positions, not the latest transaction price.',
		// 14
		budget:' Amount ',
		budgetPH:' Amount ',
		target:'Target Price ',
		benchmarkPrice:'Benchmark Price ',
		paySmall:'Buy Orders per Level (small) ',
		sellSmall:'Sell Orders per Level (small) ',
		gears:'Number of Levels ',
		gearsPH:'Number of Levels ',
		payMedium:'Buy Orders per Level (medium) ',
		sellMedium:'Sell Orders per Level (medium) ',
		payLarge:'Buy Orders per Level (large) ',
		sellLarge:'Sell Orders per Level (large) ',
		// open_cex
		openPrice:'Opening Price',
		topPrice:'Highest Price',
		downPrice:'Fallback Price',
		pullOrdersUpAndDown:'Order Volume During Rise and Fall',
		// 16
		transaction:'Volume per Deal ',
		intervalTime:'Interval Time ',
		direction:'Trend Direction ',
		oscillation:'Make Sideway',
		rise:'Go rise',
		fall:'Go down',
		operationMode:'Operation Mode',
		normalMode:'Normal',
		batchOrderMode:'Batch Order',
		unlimitedWalletMode:'Automatic Allocation',
		newWalletMode:'New Wallet',
		customMode:'Price Sequence',
		sequentialPlan:'Sequential Plan',
		systemPlan:'System Plan',
		customPlan:'Custom Plan',
		sequential:'Sequential',
		sequenceNaming:'Please name your sequence. If the name is the same, it will overwrite the corresponding sequence plan with the same name.',
		sequenceNamingOverride:'This sequence name already exists. Do you want to overwrite the existing sequence plan for {name}?',
		sequentialNameEmpty:'Please enter the sequence name',
		position:'Position ',
		newPosition:'Add New Position',
		timePoint:'Time Point',
		timeExpiredErr:'The time you set for the position has expired, please reset it',
		priceGapErr:'The price you set for the point is outside the drawing range. Are you sure you want to continue?',
		priceRangeWarnLabel:'Price range warning',
		priceRangeWarn:"The price range you've set does not include the last traded price ({price}), and the robot will continuously push the price towards the set price range.",
		priceUnitWarnLabel:'Unit warning',
		priceUnitWarn:'Please reconfirm whether the pricing unit you have entered is correct. There may be a discrepancy with the expected unit.',
		gasMultiplier:'Gas Multiplier',
		gasMultiplierTip:'The higher the gas multiplier, the higher the transaction success rate; 0x represents adaptive gas.',
		gasMultiplierTip2:'The higher the gas multiplier, the higher the transaction success rate.',
		automatic:'Automatic',
		shuaDexBotTip:'The bot will pause operation if the price exceeds the set range.Please monitor the working frequency and gas consumption.',
		shuaDexBotTip2:'If the frequency is too high, it may result in excessive gas fees.',
		shuaDexBotTipSol1:'Please note, the estimated hourly consumption is {num} {yenUnit2}.',
		shuaDexBotTipSol2:'Please note, the estimated minimum hourly consumption is {num1} {yenUnit2}, and the maximum is {num2} {yenUnit2}.',
		// 17
		targetPair:'Target Symbol ',
		scaling:'Price Scaling ',
		transactionMax:'Maximum Volume per Order ',
		transactionMaxPH:'Maximum Volume per Order ',
		// 19
		trigger:'Trigger Volume ',
		everyPurchase:'Buy in Everytime ',
		autoSell:'Whether to Sell Automatically ',//Whether to sell out automatically
		isAutoSell:'Sell automatically',//Sell out automatically

		slippage:'Highest Bid Price',
		gas:'Gas',
		transactionAmount:'Transaction Amount',
		frontrunDexBotTip:'Only use the fund wallet for single transactions. During market opening, price fluctuations may cause the transaction to fail.',
		// 20
		contract:'Target Contract ',
		orderAmount:'Order Amount ',
		multiple:'Profit Multiple ',
		multiplePH:'Profit Multiple',
		openingTime:'Listing Time ',
		openingTimePH:'Please set the opening time',
		deduction:'Whether Tax Deduction ',
		times:'Multiples',
		taxDeduction:'tax deduction',
		noTaxDeduction:'no tax deduction',
		// 24 防御机器人
		orderMin:'Minimum Order Quantity ',
		kaNum:'Stuck Quantity ',
		eatNum:'Quantity per Execution ',
		maxNum:'Coin Upper Limit ',
		minNum:'Coin Lower Limit ',
		priceTick:'Price Decimals ',
		numTick:'Number Decimals ',
		maxNumContraryTip:'The Coin Upper Limit must be greater than or equal to the Coin Lower Limit.',
		// 25 dex分批卖出
		totalShipments:'Total Shipments',
		// 26 dex刷量
		HighFrequency:'High-frequency mode',

		walletGroup:'Wallet Grouping',
		// PUMP抢买
		buyGroup:'Buy Group',
		totalPurchaseAmount:'Total Purchase Amount',
		monitoredAddress:'Monitored Address',
		// PUMP清仓
		minSellPrice:'Min Sell Price',

		success:'Robot added successfully',
		sub:'Added robots can be viewed at Trading robots',
		parameter:'Parameter settings',
		current:'Current Status',
		running:'Running',
		stopped:'Stopped',
		robotEmpty:'There is no robot added, go to add',
		robotEmpty2:'No Robots Activated Currently.',
		downLoadTip:'The logs that meet the criteria are about to be downloaded, which may take a few minutes. The exported file will be sent to your email.',
	},

	// 工具
	tools:{
		check:{
			name:'Place Buy Orders In Batch',//Batch Buy
			sub:'Assist user to place order between a certain price range',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Assist user to place order between a certain price range.</p>
				<br/>
				<p style="color: #9198AA;">According to the set price range and the total number of orders, this robot will divide the set price range into several tiers (the quantity is equal to the total number of orders), and then place orders at each tier price.

				The specific quantity of each order is a random quantity within the range of the pending quantity. 
				
				"Price range" needs to fill in the specific price. Note to fill the lower limit first, and then fill the upper limit; 
				
				"Volume" determines the random range of the volume of each buy or sell order;
				
				"Total Quantity" refers to the number of orders pending placed by the robot at one time.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when order placing is finished.</p> 
			`,
		},
		sale:{
			name:'Place Sell Orders In Batch',//Batch Sell
			sub:'Assist user to place order between a certain price range',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Assist user to place order between a certain price range.</p>
				<br/>
				<p style="color: #9198AA;">According to the set price range and the total number of orders, this robot will divide the set price range into several tiers (the quantity is equal to the total number of orders), and then place orders at each tier price.

				The specific quantity of each order is a random quantity within the range of the pending quantity. 
				
				"Price range" needs to fill in the specific price. Note to fill the lower limit first, and then fill the upper limit; 
				
				"Volume" determines the random range of the volume of each buy or sell order;
				
				"Total Quantity" refers to the number of orders pending placed by the robot at one time.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when order placing is finished.</p>  
			`,
		},
		revoke:{
			name:'Revoke Order In Batch',//Batch Cancellation
			sub:'Assist user to revoke all orders between a certain price range',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Assist user to revoke all orders between a certain price range.</p>
				<br/>
				<p style="color: #9198AA;">Revoke all users pending orders within a certain range according to the set price range. 
				"Price range" needs to fill in the specific price. Note to fill the lower limit of the range first, and then fill  the upper limit.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when order revoking is finished.</p> 
			`,
		},
		calculate:{
			name:'DEX Trading Volume Measurement',
			sub:'Calculates the volume required for the price to rise or fall to a specified price. Or enter a volume to know where the price will move to',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Calculates the volume required for the price to rise or fall to a specified price. Or enter a volume to know where the price will move to.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;"Operations: </span>
				<br/>Target price: Enter the expected target price and the tool will calculate how much volume is required. The trading volume parameter can be left blank.
				<br/>Trading volume required: Enter the expected volume and the tool will calculate where the price will move to. The price parameter can be left blank.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when calculation is finished.</p> 
			`,
		},
		transaction:{
			name:'DEX Place Order In Batches',
			sub:'Use all trading-wallets of DEX for bulk buy and sell operations',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Use all trading-wallets of DEX for bulk buy and sell operations.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;"> Operations: </span>
				<br/>Wallets number: Enter the number of wallets you want to order.
				<br/>Trading volume: Enter the total trading volume. For symbol A-B, this parameter represents how much B will be spent when buying, and how much A will be sold when selling.
				<br/>Floating range: you can control the number of orders per wallet according to the floating ratio. Order quantity range: 【(order total/order number of wallets)* minimum floating ratio ~ (order total/order number of wallets)* maximum floating ratio】, such as floating ratio 40%, order quantity range: 【(order total/order number of wallets)*60% ~ (order total/order number of wallets)*140%】
				<br/>Trading types: buy, or sell.
				<br/>Interval Time: The number of seconds between each order in a batch.
				<br/>Gas Multiplier: Adjust the multiplier of operational gas fees to optimize transaction speed and success rate, defaulting to 1x.
				<br/>Slippage: The difference between the execution price and the expected price of a trade.
				<br/>Wallet Grouping: By selecting a wallet grouping, only the wallets in the chosen group will participate in placing orders.
				</p>
			`,
		},
		// balance:{
		// 	name:'DEX View Trading-wallets Balance',
		// 	sub:'Calculate asset balances information in all trading-wallets',
		// 	html:`
		// 		<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Help user to calculate asset balances information in all trading-wallets. </p>
		// 	`,
		// },
		balance2:{
			name:'DEX View/Refresh Trading-wallets Balance',
			sub:'Calculate asset balances information in all trading-wallets, and  allow to manually refresh trading-wallet balance',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Help user to calculate asset balances information in all trading-wallets, and  allow to manually refresh trading-wallet balance when there is issue during obtaining balance. </p>
			`,
		},
		wallet:{
			// name:'DEX trading-wallet creation tool',
			name:'Trading-wallet Create',
			sub:'Create trading-wallets for placing orders in batches',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function:</span>Create trading-wallets for placing orders in batches.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations:</span>
				<br/>Chain name: The chain where wallets are created, such as BSC, MATIC, OEC, HECO, etc.
				<br/>Number of trading-wallets: fill in the number, which represents how many trading-wallets to create
				<br/>Transaction restriction:
				<br/>&nbsp;&nbsp;Unlimited (Selected wallet can place both buy and sell orders)
				<br/>&nbsp;&nbsp;Only buy (Selected wallet can only place buy orders.)
				<br/>&nbsp;&nbsp;Only sell (Selected wallet can only place sell orders.)
				</p>
			`,
		},
		distribution:{
			// name:'DEX allocate assets',
			name:'Allocate Assets',
			sub:'The specified assets can be allocated from the bound funds-wallet to the trading-wallet created by the trading-wallet creation tool',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The specified assets can be allocated from the bound funds-wallet to the trading-wallet created by the trading-wallet creation tool.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Chain name: The chain where wallets are created, such as BSC, MATIC, OEC, HECO, etc.
				<br/>Token: The abbreviation of the token's name to be allocated, such as USDT, 
				<br/>Total amount: The total amount of tokens to be allocated.
				<br/>Allocation type:
				<br/>&nbsp;&nbsp;1- Random allocation (random proportion allocated to each trading-wallet, may allocate a large floating gap)
				<br/>&nbsp;&nbsp;2- Average floating (according to the floating range you input, the funds allocated to each wallet will be allocated around the average, 0% is the average allocation, >=80% will be allocated according to the random strategy)
				<br/>&nbsp;&nbsp;3- Conditional allocation (Filter the wallet to be allocated and allocate according to the criteria you entered)
				</p>
			`,
			// <br/>钱包少于:设置需要接受分拨的钱包的资产条件</p>
			// <br/>浮动范围:每个钱包平均分拨</p>
		},
		pool:{
			// name:'DEX collect assets',
			name:'Collect Assets',
			sub:'Collect specified assets from trading-wallets into the funds-wallet',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Collect specified assets from trading-wallets into the funds-wallet.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Chain name: The chain where wallets are created, such as BSC, MATIC, OEC, HECO, etc.
				<br/>Token: The abbreviation of the token's name to be collected, such as USDT, BNB, etc.
				<br/>Total amount: The total amount of tokens required to be collected.</p>
			`,
		},
		empower:{
			name:'DEX Wallets Authorization',
			name2:'Token Approve',
			sub:'Authorize the funds-wallet and trading-wallets to trade in a certain token',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>With this tool, authorize the funds-wallet and trading-wallets to trade in a certain token</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Authorize token: Enter token value</p>
			`,
		},
		addToken:{
			name:'Add New Token',
			sub:'Add a new contract to be traded to the system',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>With this tool, you can add a new contract that needs to be traded to the system.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Token: The abbreviation of the token name. This is usually an acronym, such as BTC, ETH, etc.
				<br/>Contract Address:The contract address of the Token
				<br/>Decimals: Indicates how many decimal places the Token can be divided into
				<br/>Transaction Fee: The transaction fee for the trade</p>
				`,
		},
		holderList:{
			name:'Retail Holder Position List',
			sub:'The retail holder position list displays position information and supports note editing',
		},
		positionAddress:'Position Address',
		positionQuantity:'Position Quantity',
		positionPercentage:'Position Percentage',
		lastUpdated:'Last Updated',
		remark:'Remark',
		editRemark: 'Edit Remark',
		viewRemark: 'View Remark',

		token:'Token',
		tokenPH:'Abbreviation of the token name',
		address:'Contract Address',
		decimals:'Decimals',
		fee:'Fee',
		addPair:'Add Pair',
		quoteToken:'Quote Token',
		pairAddress:'Pair address',
		formatErr:'{token} format error, please check for any symbols.',
		// dex交易钱包管理
		manage:{
			name:'DEX Wallets Manage',
			sub:'Create DEX trading-wallets, DEX Allocate Assets, DEX Collect Assets',
		},
		// dex交易钱包刷新
		refresh:{
			name:'Refresh Balance',
			sub:'Manually refresh the trading-wallets balance information',
		},
		// 导入交易钱包
		importWallet:{
			name:'Import Trading-wallets',
		},
		// NFT钱包管理
		manageNFT:{
			name:'NFT Wallets Manage',
			sub:'Create wallets, Allocate Assets, Collect Assets',
		},
	},

	// 数据报表
	data:{
		recent7:'Last 7 days',
		recent30:'Last 30 days',
		allocation:'Asset Allocation',
		dailyTrading:'Daily trading volume',
		gas:'Service fee',
		buy:'Buy',
		sell:'Sell',
		curve:'Asset Trend',
		nftWait:'NFT is not currently supported, please look forward to it!',
		nftWait2:'Do not support NFT exchange data statistics, please look forward to!',
		endTime:'The chart data ends at ',
		export:'Export',
		dailyRecord:"Today's Data",
		historyRecord:'Historical Data',
		snapshot:'Asset Snapshot',
		curve2:'Asset Movement Trend',
	},
	// 资产报表
	property:{
		propertyEmpty:'No reports found',//'No asset report yet',
		selectTime:'Time Range',
		option1:'1 month',
		option2:'3 months',
		option3:'6 months',
	},

	// 报警系统
	alarmSystem:{
		addAlarm:'New Alert',
		telegram:'Bind to telegram',//Contact us on Telegram
		parameter:'Settings',
		currentState:'Current Status',
		operation:'Alert operational',
		stopped:'Alert terminated',
		noData:'No alerts available',//'No alarm system activated yet',
		priceThan:'When price higher than ',
		priceBelow:'When price lower than ',
		less:' less than ',
		mutation :'Extent of price change ',
		conditions:'Arbitrage Robot Detection',
		colseRobot:'Turn off the robot',
		addTrigger:'Add New Alert',
		editTrigger:'Edit Alert',//'Edit an alarm',

		activateAlarm:'Activate the alarm system',
		alarmSystem:'Alarm System',
		title1: 'Activate alarm system based on coin price',
		title2: 'Activate alarm system based on assets',
		optionalTip:'The following content is optional',
		priceThan2: 'Coin price is higher than?',
		priceBelow2: 'Coin price is lower than?',
		less2: 'Assets of {token} are less than?',
		mutation2: 'Coin price mutation service is at what percentage?',
		conditions2: 'Activate alarm system when arbitrage bot appears in the market?',
		colseRobot2:'Do you want to turn off the robot when an alert occurs?',
	},

	// 续费开通
	renew:{
		account:'Personal Account',
		balance:'Account Balance',
		balanceDetail:'Balance Details',
		vipNow:'Current Package',
		remaining:'{time} left',
		expired:'Expired',
		remainingMonth:' month ',
		remainingDay:' day',
		remainingMonths:' months ',
		remainingDays:' days',
		contact:'Customer Service',

		opentime:'Subscription Duration',
		month:'month(s)',//month
		individual:'',//个
		month2:' month(s)',//month
		openings:'Trading Pairs Quantity',
		activate:'Activate permission',//Activate //Get permission/ Activate permission
		switchPackage:'Switch package',
		// confirmation:'Information confirmation',
		confirmation1:'Information confirmation (Renew)',
		confirmation2:'Information confirmation (Switch package)',
		grade:'Account level',
		validity:'Valid after recharge',
		openingsTip:'（Number of activated trading pairs refers to how many trading pairs that robots can work at the same time）',
		cost:'Cost',
		activityCost:'Activity price: ',
		stillNeed:'Still to be paid',
		refund:'Refund',
		refundTip:'Refund to your Light-wallet',//Tips: 
		rechargeNeed:'Need to recharge',
		rechargePay:'Recharge and Renewal',
		rechargePayTip1:"【Note: After clicking 'Recharge and Renewal', your wallet will be temporarily locked. After detecting your recharge action, the system will automatically renew and then unlock your wallet. (If you want to stop this operation and unlock the wallet, please contact customer service.)】",//充值支付提示-续费
		rechargePayTip2:"【Note: After clicking 'Recharge and Renew', your wallet will be temporarily locked. After detecting your recharge action, the system will automatically switch to the plan you modified and unlock your wallet. (If you want to stop this operation and unlock the wallet, please contact customer service.)】",//充值支付提示-切换套餐

		package:'Package benefits',
		include:'Include ',
		origin:'Original ',

		subBalance:' balance',
		// 充值
		rechargeType:'Chain to recharge from',
		rechargeAddress:'Recharge address',
		copyAddress:'Copy',
		tip:'Note',
		rechargeTip1:'Please confirm only USDT ( {type} ) is send to this address. If other tokens are sent to this address, they will not be retrieved and there is no compensation. After the block is confirmed, system would inform that deposit success.',
		// rechargeTip2:'Light-wallet currently does not support the recharge function, please understand the inconvenience brought to you, if you need to provide help, please contact the business customer service.',
		renewRecord:'Recharge and renew records',
		only5:'（Only show the most recent 5 records）',
		only10:'（Only show the most recent 10 records）',
		renewAddress:'Address',
		renewAmount:'Amount',
		renewTime:'Time',
		renewSource:'Chain',
		renewState:'Status',
		renewSuc:'Success',
		endTime:'Expiration time (Account)',
		blockQuery:'Block query',
		// 提现
		address:' address',
		// total:'Total balance',
		min:'Minimum withdraw amount',
		service:'Service fee',
		withdrawalType:'Wallet',
		withdrawalChain:'Chain to withdraw to',
		withdrawalAmount:'Withdraw amount',
		withdrawalHolder:'Minimum 50 USDT, please fill in a number',
		withdrawalAddress:'Withdraw address',
		withdrawalTip1:"Please confirm to fill in the correct USDT ( {type} ) For any assets lost caused by users' personal issues, LIGHT will not take any responsibility. These issues can be conclued but not limited as followings: the withdraw address you entered is not your own wallet address; or any necessary parameters are not filled in exactly; etc. In order to ensure the safety of users' assets, large withdrawals require manual review which usually within 10-30 minutes.",
		// withdrawalTip2:'Light-wallet currently does not support the withdrawal function, please understand the inconvenience brought to you, if you need to provide help, please contact the business customer service.',
		withdrawalTip2:"Please confirm to fill in the correct USDT For any assets lost caused by users' personal issues, LIGHT will not take any responsibility. These issues can be conclued but not limited as followings: the withdraw address you entered is not your own wallet address; or any necessary parameters are not filled in exactly; etc. In order to ensure the safety of users' assets, large withdrawals require manual review which usually within 10-30 minutes.",
		withdrawalRecord:'Withdraw records',
		withdrawalTime:'Withdraw time',
		currentState:'Current Status',
	},
	// 续费时长
	renewTime:[
		'Month',
		'Quarter',
		'Half a year',
		'Year'
	],
	renewTime2:[
		'Month',
		'Quarter (20% off)',
		'Half a year (30% off)',
		'Year (40% off)'
	],
	// 充值续费提现状态
	state:[
		'Background Renewal successful',//0
		'Renewal is processing',//1
		'Renewal successful',//2
		'Renewal failed',//3
		'Switch package is processing',//4
		'Switch package successful',//5
		'Switch package failed',//6
		'Under application',//7
		'Success',//8
		'Failed',//9
		'Withdrawal approved',//10
		'Recharge is processing',//11
		'Recharge successful',//12
		'Recharge failed',//13
	],
	// 等级
	level:[
		'Individual Package',
		'Enterprise Package',
		'Professional Package',
		'DEX Package',
		'NFT Package',
	],

	// 用户中心
	user:{
		data:'Account Information',
		userName:'Username',
		lv:'Level',
		upORren:'Upgrade/Renew',
		autoRenew:'Automatic renewal',
		autoRenewTip:'Automatic renewal: When the account is valid for 3 days remaining, and the balance of your Lightning Wallet meets the fee of the current package, the renewal will automatically proceed.',
		help:'Enable Tutorial',//Guide
		security:'Security',
		modifyNum:'Change Phone number',
		changePwd:'Change Password',
		bindEmail:'Bind Email',
		changeEmail:'Change Email',
		safeLogin:'Enable Email Verification',//'Secure Login',
		multiDeviceLogin:'Multi-Device Login',
		ipVer:'IP Verification',
		ipRecommend:'Not recommended close',
		loginHistory:'Login History',
		loginHistoryTitle:'Login History',
		viewRecords:'View',
		loginTime:'Login time',
		
		language:'Language',
		changeLanguage:'Change Language',

		model:'Model',
		dark:'Dark',
		light:'Light',//日间模式
	},

	// 邀请好友
	invite:{
		code:'Invite Code',
		link:'Invite Link',
		rules:'How it works',
		rules1:'Refer your friends via the referral link or code and assist them in starting a robot.',
		rules2:'You will receive 20% of the revenue generated.',
		revenue:'Total Rewards',
		today:'Number of referrals today',
		total:'Total number of referrals',
		record:'Referral Records',
		account:'Account',
		money:'Amount',
		pay:'Payment Date',
	},

	// 404
	notFount:{
		msg:'Sorry, this page is lost.',
		backBtn:'VISIT HOME',
	},
}
